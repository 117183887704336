<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name : "Новый объект аренды" }}
      </template>
      <v-row class="mt-1 pt-2">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model :ref="'field_' + name" v-model="data" :model="getFormModel([name], model)" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id && getAccess('rent.delete', { user: [data.createdby_id] })" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getAccess, getForm, submitForm, genModel, removeEl } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm, genModel, removeEl],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.rent,
      model: [],
      modelForm: null,
      removeDialogShow: false,
    };
  },
  computed: {
    config() {
      return this.id ? this.m.editForm : this.m.createForm;
    },
  },
  created() {
    // this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) this.afterFetchData();
      }
    },
  },
  methods: {
    afterFetchData() {
      this.model = this.calcModel();
    },
    calcModel() {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      const config = this.config;
      console.log("run calc", config);
      let model = JSON.parse(JSON.stringify(this.m.form));
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.fillFormFromModel(model);

      return model;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "rent" });
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
    },
  },
};
</script>
